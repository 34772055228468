import React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import About from "../components/Repeating/About";
import CallToAction from "../components/Repeating/CTA";
import BusinessServiceSidebar from "../components/Repeating/BusinessServiceSidebar";
import BusinessServiceSlider from "../components/Repeating/BusinessServiceSlider";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Business Tax Planning Services | DC, MD & VA | DeBlanc"
        description="Create a financial strategy that'll pay off in dividends. Call DeBlanc + Murphy for business tax planning services. Serving the greater Washington, DC, area."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="pt-20 pb-10 md:py-14">
        <div className="container">
          <div className="max-w-[800px]">
            <h1>Business Tax Planning Services</h1>
            <p className="mb-0 md:text-xl">
              Tax planning is a long-term financial strategy that can pay off in
              dividends. Let us show you how we can save you money. Serving the
              greater Washington, DC area.
            </p>
          </div>
        </div>
      </section>

      <section className="relative mb-4 h-[375px] md:mb-32 md:h-[504px]">
        <BgImage
          image={getImage(data.parallaxImage.childImageSharp.gatsbyImageData)}
          className="h-full w-full bg-fixed"
        ></BgImage>
        <div className="gradient-blend absolute bottom-0 h-28 w-full"></div>
      </section>

      <section className="relative mb-20 md:mb-32">
        <div className="container">
          <div className="grid gap-y-20 md:grid-cols-12">
            <div className="md:col-span-8 md:col-start-1">
              <div className="mb-16">
                <h2>Get Tax Breaks & Minimize Your Tax Liabilities</h2>
                <p>
                  Business tax planning helps businesses successfully and
                  legally reduce their tax liabilities. And contrary to popular
                  belief, you don’t have to be running a Fortune 500 company to
                  take advantage of tax breaks.
                </p>
                <p>
                  When you partner with DeBlanc, Murphy & Murphy, we’ll ensure your
                  business is tax compliant and share tax-saving strategies to
                  maximize your after-tax income. You’ll also get a
                  comprehensive list of commonly overlooked deductions to help
                  you limit your tax liability.
                </p>
              </div>

              <div className="mb-16">
                <h3>The Many Advantages of Tax Planning</h3>
                <p>A good tax plan will limit your tax liability and:</p>

                <ul className="styled-list-plus mb-6">
                  <li>Ensure economic stability for your business</li>
                  <li>
                    Help you see the big picture so you can make financial
                    projections, strategic moves, and smart investments to grow
                    your business
                  </li>
                  
                  <li>
                    Give you the insights to make smart decisions, such as when
                    it’s a good time to make a large purchase
                  </li>
                  <li>Reduce your stress, especially during tax season</li>
                  <li>
                    Give you back time so you can focus on growing your business
                  </li>
                  <li>Reduce errors when filing</li>
                </ul>
                <p>
                  Tax planning can also put you ahead of the competition, which
                  is what every business owner wants. Whether you’re a startup
                  or have been in the game for a long time, it’s never too early
                  or late to begin.
                </p>
              </div>

              <div>
                <h3>Businesses We Work With</h3>
                <p>
                  We’re a full-service CPA firm that works with clients big and
                  small—we partner with large corporations, offer small business
                  tax planning services, and help every type of business in
                  between, including:
                </p>
                <ul className="styled-list-plus mb-6">
                  <li>Sole Proprietorships</li>
                  <li>Limited Liability Corporations</li>
                  <li>Corporations</li>
                  <li>Partnerships</li>
                  <li>Non-Profits</li>
                  <li>Start-ups</li>
                </ul>

                <ButtonSolid modal="modal-contact" text="Get a Quote" />
              </div>
            </div>
            <div className="hidden md:col-span-4 md:col-end-13 md:block">
              <BusinessServiceSidebar activeService={1} />
            </div>
          </div>
        </div>
      </section>

      <Testimonials />
      <BusinessServiceSlider hideService={1} />
      <About paddingTop={true} />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    parallaxImage: file(
      relativePath: { eq: "2.2 Business Tax Planning/1.0 Hero.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

export default Page;
